var imagesLoaded = require('imagesloaded');

loadFont(
    'https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300..700&family=Noto+Sans+TC:wght@300..700&display=swap'
)

$(function(){
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const indexVideo = document.getElementById("indexVideo");

    imagesLoaded( document.querySelector('#container'), function( instance ) {
        // console.log('all images are loaded');
        if($("#loading").length > 0){
            setTimeout(function(){
                $("#loading").fadeOut(500, function(){
                    $("#loading").remove();
                })

                if($("#indexVideo").length > 0){
                    indexVideo.play();
                }
                $("body").removeClass("hidebar");
            }, 1500)
        }
        svgimg();
    });
    
    $("body").on("click", "#menuBtn", function(e){
        const btn = $(this).find(".hamburgerMenu");
        $("body").toggleClass("hidebar");
        $("#mainmenu").toggleClass("d-none");
        $("#mainmenu-wrap").toggleClass("d-none");
        btn.toggleClass('open');
        if(btn.hasClass("open")){
            $(this).attr("aria-expanded","true");
        }else{
            $(this).attr("aria-expanded","false");
        }

        $(".closeText").toggleClass("d-none").toggleClass("show");   
        $(".nav-logo a").trigger("focus");
        e.preventDefault();
    });

    // $("#closeBtn").on("focusout", function (event) {
    //     menuClose();
    // });

    // 按esc可以關閉 main-toggle-nav
    $(document).on("keydown", function(e) {
        const toogleNav = $('#mainmenu');

        // ESCAPE key pressed
        if (e.keyCode == 27) {
            if(!toogleNav.hasClass("d-none")){
                menuClose();
            }
        }
    });

    $("body").on("click", ".scrollto", function(e){
        var target = $(this).attr("href");
        if($(target).length > 0){
            if(isSafari){
                $("body, html").animate({
                    scrollTop: $(target).offset().top
                }, 600);
            }else{
                window.scroll({
                    top: $(target).offset().top,
                    behavior: 'smooth'
                });
            }
        }
        e.preventDefault();
    });

    $("body").on("click", ".gotop", function(e){
        if(isSafari){
            $("body, html").animate({
                scrollTop: 0
            }, 600);
        }else{
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }

        $("#brand-title").trigger("focus");
        
        e.preventDefault();
    });

    $("body").on("click", ".disable", function(e){
        e.preventDefault();
    });
})

$(window).on("scroll", function(){
    var scrolltop = $(window).scrollTop();

    if(scrolltop > $(window).height()){
        $(".line-icon").addClass("in");
        $(".gotop").addClass("show");
    }else{
        $(".line-icon").removeClass("in");
        $(".gotop").removeClass("show");
    }
})

function menuClose(){
    $(".menuBtnWrap").toggleClass("d-none");
    $("#closeBtn").toggleClass("d-none");

    $("#mainmenu").toggleClass("d-none");
    $("body").toggleClass("hidebar");
    $("#mainmenu-wrap").toggleClass("open");
    $("#menuBtn").toggleClass("open");
    $(".main-nav").toggleClass("d-none");
    $("#closeText").removeClass("show").addClass("d-none");
}

function svgimg(){
    $('img.svgimg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');
    });
}

function loadFont(url) {
    // the 'fetch' equivalent has caching issues
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
            let css = xhr.responseText
            css = css.replace(/}/g, 'font-display: swap; }')

            const head = document.getElementsByTagName('head')[0]
            const style = document.createElement('style')
            style.appendChild(document.createTextNode(css))
            head.appendChild(style)
        }
    }
    xhr.send()
}